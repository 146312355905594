<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="registerForm" class="login-form" label-position="left" :rules="rules">
      <div class="title-container">
        <h3 class="title">注册</h3>
      </div>
      <div class="register_text">
        <span>已有账号？<a class="go-to-login" href="/login">立即登录</a></span>
      </div>
      <el-form-item prop="user">
        <span class="svg-container">
          <i class="el-icon-user"></i>
        </span>
        <el-input ref="user" v-model="registerForm.user" placeholder="用户名" name="user" type="text" />
      </el-form-item>
      <el-form-item prop="password">
        <span class="svg-container">
          <i class="el-icon-lock"></i>
        </span>
        <el-input ref="password" v-model="registerForm.password" placeholder="密码" name="password" type="password" show-password />
      </el-form-item>
      <el-form-item prop="email">
        <span class="svg-container">
          <i class="el-icon-connection"></i>
        </span>
        <el-input ref="email" v-model="registerForm.email" placeholder="邮箱" name="email" type="text" />
      </el-form-item>
      <el-form-item prop="phone_number">
        <span class="svg-container">
          <i class="el-icon-mobile-phone"></i>
        </span>
        <el-input ref="phone_number" v-model="registerForm.phone_number" placeholder="手机号" name="phone_number" type="text" />
      </el-form-item>
      <el-form-item prop="gender">
        <span class="svg-container">
          <i class="el-icon-orange"></i>
        </span>
        <el-radio v-model="registerForm.gender" label="男">男</el-radio>
        <el-radio v-model="registerForm.gender" label="女">女</el-radio>
      </el-form-item>
      <el-form-item prop="university">
        <span class="svg-container">
          <i class="el-icon-house"></i>
        </span>
        <el-input ref="university" v-model="registerForm.university" placeholder="所属大学" name="university" type="text" />
      </el-form-item>
      <el-form-item prop="invitation_code">
        <span class="svg-container">
          <i class="el-icon-s-opportunity"></i>
        </span>
        <el-input ref="invitation_code" v-model="registerForm.invitation_code" placeholder="邀请码" name="invitation_code" type="text" />
      </el-form-item>
      <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="register">注册</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      rules: {
        user: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: '邮箱不能为空',
            trigger: 'blur'
          }
        ],
        phone_number: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          }
        ],
        gender: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'blur'
          }
        ],
        university: [
          {
            required: true,
            message: '所属大学不能为空',
            trigger: 'blur'
          }
        ],
        invitation_code: [
          {
            required: true,
            message: '推荐码不能为空',
            trigger: 'blur'
          }
        ],
      },
      loading: false,
      registerForm: {}
    }
  },
  methods: {
    register() {
      this.$refs['loginForm'].validate(valid => {
        if (valid) {
          this.$api.user.register(this.registerForm).then(res => {
            if (res.data.code === 200) {
              this.$message.success({
                message: '注册成功，请登录',
                center: true,
                duration: 2000
              })
              this.$router.push('/login')
            } else {
              this.$message.error({
                message: res.data.msg,
                center: true,
                duration: 5000
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.register_text {
  color: #8c939d;
  display: flex;
  justify-content: right;
}
.go-to-login {
  color: #33aef0;
}
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 50px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
